/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application
// logic in a relevant structure within app/javascript and only use these pack
// files to reference that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the
// appropriate layout file, like app/views/layouts/application.html.erb

// JS
import Rails from 'rails-ujs';
import 'core-js';

import 'regenerator-runtime/runtime';
import 'document-register-element';
import 'csrf-xhr';
import 'custom-event-polyfill';
import 'classlist.js';
import L from 'leaflet';
import 'bootstrap.native';


// We need to import the flags library this way to enable resolution of
// relative image paths.
import 'flag-icon-css/css/flag-icon.css';
import 'tippy.js/themes/light-border.css';
import 'leaflet/dist/leaflet.css';
import '../stylesheets/temporary.scss';
import '../javascripts/vendor/cookieControl-9.3.3.min.js';
import '../javascripts/cookies.js';
// CSS

import '../stylesheets/application.scss';
/* This code is needed to properly load the images in the Leaflet CSS */
delete L.Icon.Default.prototype._getIconUrl;

const iconRetinaUrl = require('../images/leaflet/marker-icon-blue-large.png');
const iconUrl = require('../images/leaflet/marker-icon-blue-large.png');
const shadowUrl = require('leaflet/dist/images/marker-shadow.png');

L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
});

// Images
require.context('../images', true);

Rails.start();
