// eslint-disable-next-line import/prefer-default-export
export function replacePerspectiveCookieControlHtmlValidation() {
  document.getElementsByTagName('style').forEach((styleTag) => {
    // eslint-disable-next-line no-param-reassign
    if (styleTag.innerHTML.indexOf('perspective:0;') !== -1) {
      const { parentNode } = styleTag;
      parentNode.removeChild(styleTag);
    }
  });
}
