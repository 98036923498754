import { replacePerspectiveCookieControlHtmlValidation } from './html-validation-fixes';

document.addEventListener('DOMContentLoaded', () => {
  // In case adblock prevents Cookie Control from loading
  /* global CookieControl */
  if (!CookieControl) {
    return;
  }
  CookieControl.load({ // eslint-disable-line no-undef
    // setup
    apiKey: document
      .querySelector('meta[name="cookie_control_api_key"]')
      .getAttribute('content'),
    product: document
      .querySelector('meta[name="cookie_control_product"]')
      .getAttribute('content'),
    // cookies
    necessaryCookies: [
      '_bridgeu_connect_session',
    ],
    consentCookieExpiry: 365,
    statement: {
      description:
        'For more detailed information on the cookies we use, please check our',
      name: 'Cookie policy',
      url: 'https://bridge-u.com/cookies',
      updated: '30/03/2020',
    },
    optionalCookies: [{
      name: 'analytics',
      label: 'Analytical Cookies',
      description:
        `Analytical cookies help us to improve our website by
        collecting and reporting information on its usage.`,
      recommendedState: true,
      cookies: [
        // Segment
        'ajs_anonymous_id',
        'ajs_group_id',
        'ajs_user_id',
        // Amplitude
        'amplitude_id*',
        // FullStory
        'fs_uid',
      ],
      onAccept() {
        const segmentKey = document
          .querySelector('meta[name="segment_write_key"]')
          .getAttribute('content');
        /* eslint-disable */
        !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="ZAMefOH275XFCZJeP6EdYAQP8gPr1KNG";analytics.SNIPPET_VERSION="4.13.2";
          if(segmentKey) { analytics.load(segmentKey) };
        }}();


        /* eslint-enable */
        /* global analytics */
        /* global CE2 */
        // Segment
        if (segmentKey) {
          const userDataContainer = document.getElementById('analytics_data');
          analytics.ready(() => {
            if (userDataContainer) {
              const { userId, userTraits } = userDataContainer.dataset;
              analytics.identify(userId, JSON.parse(userTraits));
              document.querySelector('.appcues-button')
                .classList
                .remove('d-none');

              // Crazy Egg Script is already loaded
              if (window.CE2) {
                CE2.identify(userTraits);
              } else {
                // if not loaded yet, set the recording identifier once the script loads
                window.CE_READY = function () {
                  CE2.identify(userTraits);
                };
              }
            }

            analytics.page();
            replacePerspectiveCookieControlHtmlValidation();
          });
        }
      },
      onRevoke() {
        document.querySelector('.appcues-button').classList.add('d-none');

        if (analytics) {
          analytics.reset();
        }

        if (window.CE2) {
          CE2.resetTracker();
        }

        replacePerspectiveCookieControlHtmlValidation();
      },
    }],
    // appearance and behaviour
    initialState: 'notify', // bottom notification, default top hides our navbar
    position: 'left', // default right overlaps our chat button
    rejectButton: false,
    acceptBehaviour: 'recommended',
    locale: 'en',
    branding: {
      fontFamily: 'Lato, Arial, sans-serif',
      fontSizeTitle: '26px',
      fontSizeHeaders: '20px',
      fontSize: '16px',
      backgroundColor: '#3A4456',
      acceptText: '#3A4456',
      acceptBackground: '#FFF',
      toggleText: '#000',
      toggleColor: '#3A4456',
      toggleBackground: '#FFF',
      removeIcon: true,
    },
  });

  const jsCookiesLink = document.querySelector('.js-cookies-link')

  if (jsCookiesLink) {
      jsCookiesLink.addEventListener('click', () => {
        // Needs enforcing close before opening
        CookieControl.open();
      });
  }
});
